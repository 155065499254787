<template>
  <svg :width="size" :height="size" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M36 4C53.6731 4 68 18.3269 68 36C68 53.6731 53.6731 68 36 68C18.3269 68 4 53.6731 4 36C4 18.3269 18.3269 4 36 4ZM36 8C20.536 8 8 20.536 8 36C8 51.464 20.536 64 36 64C51.464 64 64 51.464 64 36C64 20.536 51.464 8 36 8ZM50.1285 26.7286C50.8684 27.4686 50.9074 28.6441 50.2453 29.4299L50.1285 29.5571L32.1285 47.5571C31.3886 48.297 30.2131 48.336 29.4273 47.6739L29.3001 47.5571L20.3001 38.5571C19.519 37.776 19.519 36.5097 20.3001 35.7286C21.04 34.9887 22.2155 34.9498 23.0013 35.6118L23.1285 35.7286L30.714 43.314L47.3001 26.7286C48.0811 25.9476 49.3475 25.9476 50.1285 26.7286Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'SuccessIcon',
  props: {
    size: {
      type: Number,
      default: 72
    }
  }
}
</script>