<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3083 3C18.8978 3 20.1262 3.23723 21.4021 3.91958C22.5542 4.53571 23.4643 5.44582 24.0804 6.59786C24.7628 7.87375 25 9.10224 25 11.6917V16.3083C25 18.8978 24.7628 20.1262 24.0804 21.4021C23.4643 22.5542 22.5542 23.4643 21.4021 24.0804C20.1262 24.7628 18.8978 25 16.3083 25H11.6917C9.10224 25 7.87375 24.7628 6.59786 24.0804C5.44582 23.4643 4.53571 22.5542 3.91958 21.4021C3.23723 20.1262 3 18.8978 3 16.3083V11.6917C3 9.10224 3.23723 7.87375 3.91958 6.59786C4.53571 5.44582 5.44582 4.53571 6.59786 3.91958C7.87375 3.23723 9.10224 3 11.6917 3H16.3083ZM17.9791 15.4419L13.7771 20.6294C13.426 21.0628 12.7883 21.1253 12.3598 20.7682L10.0614 18.8528L6.96323 21.9516C7.14411 22.0857 7.33681 22.2076 7.54106 22.3168C8.4876 22.823 9.40417 23 11.6917 23H16.3083C18.5958 23 19.5124 22.823 20.4589 22.3168C21.2624 21.8871 21.8871 21.2624 22.3168 20.4589C22.4637 20.1842 22.5829 19.9121 22.6781 19.6082L17.9791 15.4419ZM16.3083 5H11.6917C9.40417 5 8.4876 5.177 7.54106 5.68321C6.73755 6.11293 6.11293 6.73755 5.68321 7.54106C5.177 8.4876 5 9.40417 5 11.6917V16.3083C5 18.5652 5.17229 19.4876 5.66305 20.4209L9.29289 16.7929C9.65861 16.4272 10.2429 16.4007 10.6402 16.7318L12.8612 18.5826L17.0831 13.3706C17.4417 12.9278 18.0972 12.8738 18.5236 13.2517L22.9887 17.2102C22.9964 16.9349 23 16.6356 23 16.3083V11.6917C23 9.40417 22.823 8.4876 22.3168 7.54106C21.8871 6.73755 21.2624 6.11293 20.4589 5.68321C19.5124 5.177 18.5958 5 16.3083 5ZM10.5 9C11.3284 9 12 9.67157 12 10.5C12 11.3284 11.3284 12 10.5 12C9.67157 12 9 11.3284 9 10.5C9 9.67157 9.67157 9 10.5 9Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'ImageIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>