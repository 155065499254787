<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.7064 9.66186C25.1705 11.1144 26.0002 12.9977 26.0002 15C26.0002 16.2695 25.667 17.4958 25.04 18.5987C25.1411 19.2021 25.4325 20.118 25.9159 21.315L26.1743 21.9388L26.2312 22.1068L26.2563 22.205C26.3872 22.8531 26.0455 23.5097 25.4304 23.7705C25.2347 23.8535 25.0226 23.8908 24.8104 23.8797C22.9024 23.7794 21.3802 23.3142 20.2704 22.4561C19.238 22.8137 18.1331 23 17.0002 23C15.8456 23 14.7196 22.8066 13.6681 22.4344C13.1475 22.2502 12.8748 21.6787 13.0591 21.1581C13.2434 20.6374 13.8149 20.3648 14.3355 20.5491C15.1726 20.8454 16.073 21 17.0002 21C18.0866 21 19.1354 20.7876 20.0868 20.3854C20.4634 20.2262 20.899 20.3123 21.1867 20.6028C21.7364 21.1577 22.5583 21.5442 23.6684 21.7437L23.9519 21.7897C23.3782 20.3343 23.0635 19.2351 23.009 18.4466C22.9943 18.2341 23.0478 18.0225 23.1618 17.8426C23.7105 16.9762 24.0002 16.0067 24.0002 15C24.0002 13.5462 23.3933 12.1684 22.2978 11.0817C21.9058 10.6927 21.9033 10.0595 22.2922 9.66746C22.6812 9.27539 23.3144 9.27288 23.7064 9.66186ZM11 3C15.9431 3 20 6.54982 20 11C20 15.4502 15.9431 19 11 19C9.86701 19 8.76217 18.8137 7.72976 18.4561C6.61997 19.3142 5.09781 19.7794 3.18981 19.8797C2.97753 19.8908 2.7655 19.8535 2.5698 19.7705C1.95463 19.5097 1.61301 18.8531 1.74386 18.205L1.76892 18.1068L1.82584 17.9388L1.95947 17.6201C2.47478 16.3764 2.79807 15.4113 2.93092 14.756L2.96016 14.5987L2.80956 14.3204C2.27996 13.2911 2 12.1637 2 11C2 6.54982 6.05693 3 11 3ZM11 5C7.10651 5 4 7.7182 4 11C4 12.0067 4.28965 12.9762 4.83837 13.8426C4.95232 14.0225 5.00584 14.2341 4.99116 14.4466C4.94056 15.1788 4.66563 16.1788 4.16723 17.4833L4.04825 17.7897L4.33178 17.7437C5.44188 17.5442 6.26381 17.1577 6.81344 16.6028C7.10116 16.3123 7.53675 16.2262 7.91334 16.3854C8.86471 16.7876 9.91355 17 11 17C14.8935 17 18 14.2818 18 11C18 7.7182 14.8935 5 11 5Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'MessagesIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>