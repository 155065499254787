<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2C17.3137 2 20 4.68629 20 8L20.0021 10.0227C20.8449 10.075 21.3826 10.2224 21.9369 10.5189C22.6 10.8735 23.1265 11.4 23.4811 12.0631C23.8663 12.7834 24 13.4756 24 14.8458V21.1542C24 22.5244 23.8663 23.2166 23.4811 23.9369C23.1265 24.6 22.6 25.1265 21.9369 25.4811C21.2166 25.8663 20.5244 26 19.1542 26H8.84583C7.47564 26 6.78342 25.8663 6.06313 25.4811C5.39998 25.1265 4.87355 24.6 4.51889 23.9369C4.13367 23.2166 4 22.5244 4 21.1542V14.8458C4 13.4756 4.13367 12.7834 4.51889 12.0631C4.87355 11.4 5.39998 10.8735 6.06313 10.5189C6.61765 10.2223 7.15554 10.0748 7.99895 10.0227L8 8C8 4.68629 10.6863 2 14 2ZM19.1542 12H8.84583C7.77757 12 7.39726 12.0734 7.00633 12.2825C6.69171 12.4508 6.45077 12.6917 6.28251 13.0063C6.07344 13.3973 6 13.7776 6 14.8458V21.1542C6 22.2224 6.07344 22.6027 6.28251 22.9937C6.45077 23.3083 6.69171 23.5492 7.00633 23.7175C7.39726 23.9266 7.77757 24 8.84583 24H19.1542C20.2224 24 20.6027 23.9266 20.9937 23.7175C21.3083 23.5492 21.5492 23.3083 21.7175 22.9937C21.9266 22.6027 22 22.2224 22 21.1542V14.8458C22 13.7776 21.9266 13.3973 21.7175 13.0063C21.5492 12.6917 21.3083 12.4508 20.9937 12.2825C20.6027 12.0734 20.2224 12 19.1542 12ZM14 15C14.5523 15 15 15.4477 15 16V19C15 19.5523 14.5523 20 14 20C13.4477 20 13 19.5523 13 19V16C13 15.4477 13.4477 15 14 15ZM14 4C11.7909 4 10 5.79086 10 8V10H18V8C18 5.79086 16.2091 4 14 4Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'LockIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>