<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 2C20.6274 2 26 7.37258 26 14C26 20.6274 20.6274 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2ZM14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C19.5228 24 24 19.5228 24 14C24 8.47715 19.5228 4 14 4ZM18.9487 10.3162L16.9487 16.3162C16.8491 16.6148 16.6148 16.8491 16.3162 16.9487L10.3162 18.9487C9.53447 19.2093 8.79073 18.4655 9.05132 17.6838L11.0513 11.6838C11.1509 11.3852 11.3852 11.1509 11.6838 11.0513L17.6838 9.05132C18.4655 8.79073 19.2093 9.53447 18.9487 10.3162ZM16.4189 11.5811L12.7906 12.7906L11.5811 16.4189L15.2094 15.2094L16.4189 11.5811Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'CompassIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>