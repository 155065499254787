<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.8722 4C22.6553 4 23.3018 4.18565 23.9537 4.53427C24.6055 4.88288 25.1171 5.39446 25.4657 6.04631C25.8143 6.69816 26 7.34473 26 9.12777V18.1542L25.999 18.245V15.017L25.9933 15.1166C25.9355 15.614 25.5128 16 25 16C24.4477 16 24 15.5523 24 15V12H4V17.4361C4 18.3276 4.09283 18.6509 4.26713 18.9768C4.44144 19.3028 4.69723 19.5586 5.02315 19.7329C5.32401 19.8938 5.62261 19.9852 6.36798 19.9984L6.56389 20H22.586L20.2929 17.7071C19.9024 17.3166 19.9024 16.6834 20.2929 16.2929C20.6834 15.9024 21.3166 15.9024 21.7071 16.2929L25.7071 20.2929C26.0976 20.6834 26.0976 21.3166 25.7071 21.7071L21.7071 25.7071C21.3166 26.0976 20.6834 26.0976 20.2929 25.7071C19.9024 25.3166 19.9024 24.6834 20.2929 24.2929L22.5913 21.9943C22.4564 21.9981 22.3112 22 22.1542 22H7.12777C5.34473 22 4.69816 21.8143 4.04631 21.4657C3.39446 21.1171 2.88288 20.6055 2.53427 19.9537C2.18565 19.3018 2 18.6553 2 16.8722V9.12777C2 7.34473 2.18565 6.69816 2.53427 6.04631C2.88288 5.39446 3.39446 4.88288 4.04631 4.53427C4.69816 4.18565 5.34473 4 7.12777 4H20.8722ZM21.4361 6H6.56389C5.67237 6 5.34908 6.09283 5.02315 6.26713C4.69723 6.44144 4.44144 6.69723 4.26713 7.02315C4.10623 7.32401 4.01476 7.62261 4.00164 8.36798L4 8.56388V10H24V8.56388C24 7.67237 23.9072 7.34908 23.7329 7.02315C23.5586 6.69723 23.3028 6.44144 22.9768 6.26713C22.6509 6.09283 22.3276 6 21.4361 6Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'MoneyTransferIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>