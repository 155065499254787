<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1295 2.69099L24.0662 11.1809C24.6624 11.7472 25 12.5335 25 13.3559V21.9972C25 23.3779 23.8807 24.4972 22.5 24.4972H16.5C15.9477 24.4972 15.5 24.0495 15.5 23.4972V17.9971C15.5 17.1687 14.8284 16.4971 14 16.4971C13.1716 16.4971 12.5 17.1687 12.5 17.9971V23.4972C12.5 24.0495 12.0523 24.4972 11.5 24.4972H5.5C4.11929 24.4972 3 23.3779 3 21.9972V13.3559C3 12.5335 3.33756 11.7472 3.93375 11.1809L12.8705 2.69099C13.5034 2.08966 14.4966 2.08966 15.1295 2.69099ZM14 4.37654L5.31125 12.6309C5.11252 12.8196 5 13.0817 5 13.3559V21.9972C5 22.2734 5.22386 22.4972 5.5 22.4972H10.5V17.9971C10.5 16.0641 12.067 14.4971 14 14.4971C15.933 14.4971 17.5 16.0641 17.5 17.9971V22.4972H22.5C22.7761 22.4972 23 22.2734 23 21.9972V13.3559C23 13.0817 22.8875 12.8196 22.6887 12.6309L14 4.37654Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'HomeIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>