<template>
  <div style="padding:10px;">
    <br/>
    <v-select
        :items="pages"
        dense
        hide-details
        label="Select the page you want to customise  "
        outlined
        v-model="selectedpage"
        @change="routepage"
    ></v-select>
  </div>
</template>

<script>

export default {
    name: "PageChange",
    props: {
        currentpage: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        selectedpage: '',
    }),
    computed: {
        pages: function () {
            return [
                {
                    text: "Home Page",
                    value: "Pages"
                },
                {
                    text: "Product Page",
                    value: "Productpage"
                },
                {
                    text: "Product Card",
                    value: "Productcard"
                },
                {
                    text: "Category Page",
                    value: "Categorypage"
                },
                {
                    text: "Wishlist Page",
                    value: "Wishlistpage"
                },
                {
                    text: "Cart & Order Page",
                    value: "Cartpage"
                },
                {
                    text: "Profile & Settings Page",
                    value: "Profilesettingpage"
                },
                {
                    text: "Login & Registration Page",
                    value: "Loginregpage"
                },
            ];
        },
    },
    mounted() {
        this.selectedpage = this.currentpage;
    },
    methods: {
        routepage() {
            // console.log(this.selectedpage);
            this.$router.push({ name: this.selectedpage, params: { uid: this.$route.params.uid } });
        },
    },
    
}
</script>
