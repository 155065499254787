<template>
  <div>
    <v-navigation-drawer
      absolute
      width="66"
      style="padding-top: 8px; padding-bottom: 8px; background-color: #f2fffd"
      v-model="$store.state.left_drawer"
      hide-overlay
      class="appbargradient">
      <div class="d-flex justify-center align-center flex-column mb-5">
        <img
          src="https://app.shopimint.com/backend/static/shopimintlogoturn.png"
          height="180"
          alt="Logo"
          class="margin_menu" />
      </div>
      <div class="d-flex justify-center align-center flex-column">
        <v-tooltip right color="blue_dark">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              large
              exact
              color="success"
              v-bind="attrs"
              v-on="on"
              class="mb-5 margin_menu"
              @click="$emit('open_launcher')">
              <AddIcon />
            </v-btn>
          </template>
          <span>{{ $tr("menu", "key_31") }}</span>
        </v-tooltip>
        <div v-for="(item, index) in items" :key="'app_navigation_' + index">
          <v-tooltip v-if="index !== 3" right color="blue_dark">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                v-bind="attrs"
                v-on="on"
                class="mb-5 margin_menu"
                :to="{ name: item.to }">
                <AppsIcon v-if="index === 0" />
                <!-- <SendIcon v-else-if="index === 1"/> -->
                <KeySquareIcon v-else-if="index === 1" />
                <MessagesIcon v-else-if="index === 2" />
              </v-btn>
            </template>
            <span>{{ $tr("menu", item.name) }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="index === 3 && $store.state.user.admin"
            right
            color="blue_dark">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                v-bind="attrs"
                v-on="on"
                class="mb-5 margin_menu"
                :to="{ name: item.to }">
                <DashboardIcon />
              </v-btn>
            </template>
            <span>{{ $tr("menu", item.name) }}</span>
          </v-tooltip>
        </div>
      </div>
      <template v-slot:append>
        <div
          class="d-flex justify-center align-center flex-column"
          style="padding-bottom: 8px">
          <v-tooltip right color="blue_dark">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                large
                exact
                v-bind="attrs"
                v-on="on"
                class="mb-6 margin_menu"
                @click="toggleTheme">
                <MoonIcon v-if="!$store.state.darkMode" />
                <SunIcon v-else />
              </v-btn>
            </template>
            <span
              v-text="
                !$store.state.darkMode
                  ? $tr('menu', 'key_7')
                  : $tr('menu', 'key_6')
              " />
          </v-tooltip>

          <img
            :src="languageflag"
            class="white--text mb-8"
            width="37"
            height="37"
            style="cursor: pointer; border-radius: 50%"
            @click="$router.push({ name: 'Profile' })" />

          <v-avatar
            color="smoke"
            :size="37"
            class="white--text"
            style="cursor: pointer"
            @click="$router.push({ name: 'Profile' })">
            <div style="padding-top: 2px">
              <UserIcon :size="22" />
            </div>
          </v-avatar>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import AppsIcon from "@/components/icons/AppsIcon";
// import SendIcon from "@/components/icons/SendIcon";
import KeySquareIcon from "@/components/icons/KeySquareIcon";
import MessagesIcon from "@/components/icons/MessagesIcon";
import AddIcon from "@/components/icons/AddIcon";
import SunIcon from "@/components/icons/SunIcon";
import MoonIcon from "@/components/icons/MoonIcon";
import UserIcon from "@/components/icons/UserIcon";
import DashboardIcon from "@/components/icons/DashboardIcon";
// import TranslateIcon from "@/components/icons/translateicon4.png";

export default {
  name: "AppSidebar",
  components: {
    AppsIcon,
    // SendIcon,
    KeySquareIcon,
    MessagesIcon,
    AddIcon,
    MoonIcon,
    SunIcon,
    UserIcon,
    DashboardIcon,
  },
  data: () => ({
    // TranslateIcon : 'https://app.shopimint.com/backend/static/languages/en.svg',
    //this.$store.state.current_language
    languageflag: "https://app.shopimint.com/backend/static/languages/en.svg",
    items: [
      {
        name: "key_1",
        to: "Apps",
      },
      // {
      //   name: "key_2",
      //   to: "Newsletter"
      // },
      {
        name: "key_25",
        to: "Keys",
      },
      {
        name: "key_4",
        to: "Support",
      },
      {
        name: "key_9",
        to: "Dashboard",
      },
    ],
    loading: false,
    wizard: false,
    languagemodel: false,
  }),
  watch: {
    "$store.state.current_language": {
      handler(n) {
        if (n) {
          this.languageflag =
            "https://app.shopimint.com/backend/static/languages/" +
            this.$store.state.current_language +
            ".svg";
        }
      },
    },
  },
  methods: {
    toggleTheme() {
      this.$store.commit("setDarkMode", !this.$vuetify.theme.dark);
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    openWizard(index) {
      if (!index) {
        this.$emit("open_launcher");
      }
    },
  },
  beforeMount() {
    this.languageflag =
      "https://app.shopimint.com/backend/static/languages/" +
      this.$store.state.current_language +
      ".svg";
    console.log(
      "https://app.shopimint.com/backend/static/languages/" +
        this.$store.state.current_language +
        ".svg"
    );
  },
};
</script>

<style scoped>
.appbargradient {
  background: linear-gradient(-45deg, #ffece7, #ffeef4, #e2f7ff, #e8fffa);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}
.theme--dark .appbargradient {
  background: linear-gradient(-45deg, #000000, #000000, #000000, #000000);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}
@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
