<template>
  <v-app id="auth" v-if="$store.state.isStarted" class="bgbody">
    <img
        :src="logoSvg"
        class="logo_auth"
        alt="Logo"
    />
    <v-main class="d-flex justify-center align-center align-self-center" >
      <div class="logincardbackground">
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import Logo from "../assets/images/logosvg.svg";
export default {
  name: 'AuthLayout',
  components: {

  },
  data: () => ({
    logoSvg: Logo
  }),
  mounted() {
  
  },
  created() {
    let partner = this.$route.query.partner;
    // let d = new Date();
    // d.setTime(d.getTime() + 14 * 24 * 60 * 60 * 1000);
    // this.$cookie.set("partner", partner, d);
    console.log('Referal By :'+ partner);
  }
}
</script>

<style scoped>

.bgbody{
	background: linear-gradient(-45deg, #ffd0c2, #ffd1e3, #d5f4ff, #ddfff7);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
	height: 100vh;
}

.logincardbackground{
  width: 400px; 
  background-color:aliceblue; 
  padding:25px; 
  border-radius: 25px;
}

.theme--dark .logincardbackground{
  width: 400px; 
  background-color:rgb(16, 16, 16); 
  padding:25px; 
  border-radius: 25px;
}

@-webkit-keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

</style>