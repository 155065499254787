<template>
  <div class="bar_padding">
    <PageBar :title="$tr('menu', 'key_32')" :preview="false"/>
    <v-list nav class="pt-0 pb-0">
      <v-subheader class="font-weight-medium smoke--text">
        {{ $tr('menu', 'key_33') }}
      </v-subheader>
      <v-list-item-group color="rgba(153, 162, 173, 1)">
        <v-list-item
            v-for="(item, index) in items"
            :key="'profile_navigation_'+index"
            :to="{name:item.to}" exact
        >
          <v-list-item-avatar class="null_border_radius" size="22">
            <div class="primary_icon list_icon">
              <LockIcon v-if="!index" :size="22"/>
              <MoneyTransferIcon v-if="index === 1" :size="22"/>
            </div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="black--text" v-text="$tr('menu', item.name)"/>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <v-subheader class="font-weight-medium smoke--text">
        Select Language
      </v-subheader>
      <v-google-translate @select="googleTranslateSelectedHandler" :languages="arrayOfCountries" />

      <v-subheader class="font-weight-medium smoke--text" style="margin-top:30px;">
        If you need RTL version. Please select RTL language & reload the page.
      </v-subheader>

    </v-list>

    
  </div>
</template>

<script>
import PageBar from "@/components/blocks/PageBar";
import MoneyTransferIcon from "@/components/icons/MoneyTransferIcon";
import LockIcon from "@/components/icons/LockIcon";
export default {
  name: 'ProfileSidebar',
  components: {
    PageBar,
    MoneyTransferIcon,
    LockIcon
  },
  data: () => ({
    arrayOfCountries: [
          {
            code: "en",
            name: "English",
            cname: "英语",
            ename: "English",
          },
          {
            code: "si",
            name: "Sinhala",
            cname: "සිංහල",
            ename: "Sinhala",
          },
          {
            code: "ta",
            name: "Tamil",
            cname: "தமிழ்",
            ename: "Tamil",
          },
          {
            code: "ar",
            name: "العربية",
            cname: "阿拉伯语",
            ename: "Arabic",
          },
          {
            code: "zh-CN",
            name: "Chinese (Simplified)",
            cname: "中文 (简体)",
            ename: "Chinese (Simplified)",
          },
          {
            code: "zh-TW",
            name: "Chinese (Traditional)",
            cname: "中文 (繁体)",
            ename: "Chinese (Traditional)",
          },
          {
            code: "it",
            name: "Italiano",
            cname: "意大利语",
            ename: "Italian",
          },
          {
            code: "ja",
            name: "にほんご",
            cname: "日语",
            ename: "Japanese",
          },
          {
            code: "ko",
            name: "한국어",
            cname: "韩语",
            ename: "Korean",
          },
          {
            code: "af",
            name: "Afrikaans",
            cname: "南非语",
            ename: "Afrikaans",
          },
          {
            code: "sq",
            name: "Gjuha shqipe",
            cname: "阿尔巴尼亚语",
            ename: "Albanian",
          },
          {
            code: "hy",
            name: "Հայերեն",
            cname: "亚美尼亚语",
            ename: "Armenian",
          },
          {
            code: "az",
            name: "Азәрбајҹан дили",
            cname: "阿塞拜疆语",
            ename: "Azerbaijani",
          },
          {
            code: "eu",
            name: "Euskara",
            cname: "巴斯克语",
            ename: "Basque",
          },
          {
            code: "be",
            name: "беларуская мова",
            cname: "白俄罗斯语",
            ename: "Belarusian",
          },
          {
            code: "bg",
            name: "български език",
            cname: "保加利亚语",
            ename: "Bulgarian",
          },
          {
            code: "ca",
            name: "Català",
            cname: "加泰罗尼亚语",
            ename: "Catalan",
          },
          {
            code: "hr",
            name: "Српскохрватски језик",
            cname: "克罗地亚语",
            ename: "Croatian",
          },
          {
            code: "cs",
            name: "čeština",
            cname: "捷克语",
            ename: "Czech",
          },
          {
            code: "da",
            name: "Danmark",
            cname: "丹麦语",
            ename: "Danish",
          },
          {
            code: "nl",
            name: "Nederlands",
            cname: "荷兰语",
            ename: "Dutch",
          },
          {
            code: "et",
            name: "eesti keel",
            cname: "爱沙尼亚语",
            ename: "Estonian",
          },
          {
            code: "tl",
            name: "Filipino",
            cname: "菲律宾语",
            ename: "Filipino",
          },
          {
            code: "fi",
            name: "Finnish",
            cname: "芬兰语",
            ename: "Finnish",
          },
          {
            code: "fr",
            name: "Français",
            cname: "法语",
            ename: "French",
          },
          {
            code: "de",
            name: "Deutsch",
            cname: "德语",
            ename: "German",
          },
          {
            code: "el",
            name: "Ελληνικά",
            cname: "希腊语",
            ename: "Greek",
          },
          {
            code: "hu",
            name: "magyar",
            cname: "匈牙利语",
            ename: "Hungarian",
          },
          {
            code: "id",
            name: "Indonesia",
            cname: "印度尼西亚语",
            ename: "Indonesian",
          },
          {
            code: "ga",
            name: "Irish",
            cname: "爱尔兰语",
            ename: "Irish",
          },
          {
            code: "lt",
            name: "lietuvių kalba",
            cname: "立陶宛语",
            ename: "Lithuanian",
          },
          {
            code: "ms",
            name: "Malay",
            cname: "马来西亚语",
            ename: "Malay",
          },
          {
            code: "no",
            name: "norsk",
            cname: "挪威语",
            ename: "Norwegian",
          },
          {
            code: "pl",
            name: "Polski",
            cname: "波兰语",
            ename: "Polish",
          },
          {
            code: "pt",
            name: "Português",
            cname: "葡萄牙语",
            ename: "Portuguese",
          },
          {
            code: "ro",
            name: "limba română",
            cname: "罗马尼亚语",
            ename: "Romanian",
          },
          {
            code: "ru",
            name: "Русский",
            cname: "俄语",
            ename: "Russian",
          },
          {
            code: "es",
            name: "Español",
            cname: "西班牙语",
            ename: "Spanish",
          },
          {
            code: "sv",
            name: "Swedish",
            cname: "瑞典语",
            ename: "Swedish",
          },
          {
            code: "th",
            name: "ภาษาไทย",
            cname: "泰语",
            ename: "Thai",
          },
          {
            code: "tr",
            name: "Turkish",
            cname: "土耳其语",
            ename: "Turkish",
          },
          {
            code: "uk",
            name: "українська мова",
            cname: "乌克兰语",
            ename: "Ukrainian",
          },
    ],
    items: [
      {
        name: "key_24",
        to: "Profile",
      },
      {
        name: "key_26",
        to: "Transactions"
      },
    ],
  }),
  watch: {

  },
  methods: {
    googleTranslateSelectedHandler(language) {
      const { code, name, cname, ename } = language;
      console.log(code);
      console.log(name);
      console.log(cname);
      console.log(ename);
      
      // const previouslang = this.$store.state.current_language;
      if(code == 'ar'){
        this.$store.commit('setRTL', true);
      }else{
        this.$store.commit('setRTL', false);
      }
      this.$store.commit('setCurrentLanguage', code);

      // if(code != previouslang && previouslang.length >= 2 ){
      //   window.location.reload();
      // }
      // window.location.reload();
      // todo ...
    }
  },
}
</script>