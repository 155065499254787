<template>
  <svg  :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0028 2L14 2C7.37258 2 2 7.37258 2 14C2 20.6274 7.37258 26 14 26C20.6274 26 26 20.6274 26 14C26 7.3735 20.6289 2.00149 14.0028 2ZM16.3138 23.731C19.0226 23.0893 21.3052 21.3459 22.6622 19H18.3787C17.9779 20.5286 17.4296 21.7914 16.8756 22.7956C16.6868 23.1377 16.4977 23.4494 16.3138 23.731ZM18.7853 17H23.5422C23.8396 16.053 24 15.0452 24 14C24 12.9548 23.8396 11.947 23.5422 11H18.7853C18.9214 11.9244 19 12.924 19 14C19 15.076 18.9214 16.0756 18.7853 17ZM16.7612 11C16.9111 11.9097 17 12.909 17 14C17 15.091 16.9111 16.0903 16.7612 17H11.2388C11.0889 16.0903 11 15.091 11 14C11 12.909 11.0889 11.9097 11.2388 11H16.7612ZM18.3787 9H22.6622C21.3052 6.65414 19.0226 4.91069 16.3138 4.26903C16.4977 4.55064 16.6868 4.86231 16.8756 5.20442C17.4296 6.2086 17.9779 7.47145 18.3787 9ZM11.6862 4.26903C8.97742 4.91069 6.69483 6.65414 5.33782 9H9.62131C10.0221 7.47145 10.5704 6.2086 11.1244 5.20442C11.3132 4.86231 11.5023 4.55064 11.6862 4.26903ZM11.698 9C12.0357 7.86862 12.4578 6.92773 12.8756 6.17058C13.2791 5.43918 13.6801 4.87666 14 4.47836C14.3199 4.87666 14.7209 5.43918 15.1244 6.17058C15.5422 6.92773 15.9643 7.86862 16.302 9H11.698ZM9.21468 11H4.4578C4.16035 11.947 4 12.9548 4 14C4 15.0452 4.16035 16.053 4.4578 17H9.21468C9.07857 16.0756 9 15.076 9 14C9 12.924 9.07857 11.9244 9.21468 11ZM9.62131 19H5.33782C6.69483 21.3459 8.97742 23.0893 11.6862 23.731C11.5023 23.4494 11.3132 23.1377 11.1244 22.7956C10.5704 21.7914 10.0221 20.5286 9.62131 19ZM11.698 19H16.302C15.9643 20.1314 15.5422 21.0723 15.1244 21.8294C14.7209 22.5608 14.3199 23.1233 14 23.5216C13.6801 23.1233 13.2791 22.5608 12.8756 21.8294C12.4578 21.0723 12.0357 20.1314 11.698 19Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'GlobeIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>