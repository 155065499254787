<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9562 3L16 3C16.5523 3 17 3.44772 17 4C17 4.55229 16.5523 5 16 5H12C10.5834 5 9.58104 5.00078 8.79744 5.0648C8.02552 5.12787 7.55435 5.24729 7.18404 5.43597C6.43139 5.81947 5.81947 6.43139 5.43597 7.18404C5.24729 7.55435 5.12787 8.02552 5.0648 8.79744C5.00078 9.58104 5 10.5834 5 12V16C5 17.4166 5.00078 18.419 5.0648 19.2026C5.12787 19.9745 5.24729 20.4457 5.43597 20.816C5.81947 21.5686 6.43139 22.1805 7.18404 22.564C7.55435 22.7527 8.02552 22.8721 8.79744 22.9352C9.58104 22.9992 10.5834 23 12 23H16C17.4166 23 18.419 22.9992 19.2026 22.9352C19.9745 22.8721 20.4457 22.7527 20.816 22.564C21.5686 22.1805 22.1805 21.5686 22.564 20.816C22.7527 20.4457 22.8721 19.9745 22.9352 19.2026C22.9992 18.419 23 17.4166 23 16V12C23 11.4477 23.4477 11 24 11C24.5523 11 25 11.4477 25 12V16.0438C25 17.4068 25 18.4908 24.9286 19.3654C24.8554 20.261 24.7023 21.0247 24.346 21.7239C23.7708 22.8529 22.8529 23.7708 21.7239 24.346C21.0247 24.7023 20.261 24.8554 19.3654 24.9286C18.4908 25 17.4068 25 16.0438 25H11.9562C10.5932 25 9.50916 25 8.63458 24.9286C7.73898 24.8554 6.9753 24.7023 6.27606 24.346C5.14708 23.7708 4.2292 22.8529 3.65396 21.7239C3.29768 21.0247 3.14462 20.261 3.07144 19.3654C2.99999 18.4908 2.99999 17.4068 3 16.0438V11.9562C2.99999 10.5932 2.99999 9.50917 3.07144 8.63458C3.14462 7.73898 3.29768 6.9753 3.65396 6.27606C4.2292 5.14708 5.14708 4.2292 6.27606 3.65396C6.9753 3.29768 7.73898 3.14462 8.63458 3.07144C9.50917 2.99999 10.5932 2.99999 11.9562 3Z" fill="currentColor"/>
    <path d="M25 6C25 7.65685 23.6569 9 22 9C20.3431 9 19 7.65685 19 6C19 4.34315 20.3431 3 22 3C23.6569 3 25 4.34315 25 6Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'PushIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>