<template>
  <div>
    <v-toolbar flat color="white" class="bar_page" height="72">
      <v-btn v-if="drawer" icon ref="footer" color="smoke" @click="setDrawer">
        <MenuIcon />
      </v-btn>

      <v-btn v-if="back" icon color="smoke" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div v-if="pagetype == 'pages' ">
        <PageChange :currentpage="title" />
      </div>
      <v-toolbar-title v-else>
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="this.$route.params.uid !== undefined "
        class="mx-2"
        :loading="cacheloading"
        fab
        dark
        small
        color="blue"
        @click="refreshcache"
      >
        <v-icon dark> mdi-flash </v-icon>
      </v-btn>
      <v-btn
        v-if="this.$route.params.uid !== undefined "
        depressed
        class="ml-3 text-left previewbtn"
        @click="dialog = true"
      >
        <span class="texthide">Preview Your App &nbsp;</span>
        <div>
          <QrIcon />
        </div>
      </v-btn>
      <!-- <template v-if="preview">
        <v-btn
            icon
            color="smoke"
            @click="setPreview"
        >
          <ChevronRightIcon v-if="$store.state.right_drawer"/>
          <ChevronLeftIcon v-else/>
        </v-btn>
      </template> -->

      <slot name="actions"></slot>
      <template v-if="tabs" v-slot:extension>
        <v-tabs
          align-with-title
          v-model="tabModel"
          @change="$emit('onChange', tabModel)"
        >
          <v-tabs-slider color="primary" />
          <v-tab v-for="(tab, i) in tabs" :key="`${i}_page_tab`">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-divider />
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
        <v-card-title>
          <!-- <span class="text-h5">Preview your Mobile App</span> -->
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="6" sm="12" cols="12">
                <div class="text-center">
                  <div v-if="!this.apps.icon" class="smoke--text">
                    <ImageIcon v-if="!this.apps.icon" :size="32" />
                  </div>
                  <img
                    v-else
                    :src="`${this.apps.icon}`"
                    style="border-radius: 10px; padding: 5px"
                  />
                  <h1 class="pb-3">
                    {{ this.apps.name}}
                  </h1>
                  <h3 class="pb-5">
                    {{ this.apps.link}}
                  </h3>
                </div>
                <center>
                  <img
                    :src="`https://api.qrserver.com/v1/create-qr-code/?data=${this.apps.app_deeplink}&amp;size=300x300`"
                  />
                </center>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <v-row>
                  <v-col>
                    <h1>Preview your App</h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3>
                      Follow this step by step guide on how to preview your app
                    </h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3>Step 1 :</h3>
                    <h3>
                      Scan the QR code to Download Our App or click one of the
                      following buttons.
                    </h3>
                    <table>
                      <tr>
                        <td>
                          <a href="https://play.google.com/store/apps/details?id=com.shopimint.app&hl=en&gl=US" target="_blank"
                            ><img
                              src="https://shopimint.vercel.app/_next/image/?url=%2F_next%2Fstatic%2Fmedia%2Fplaystore.99f6aca0.png&w=750&q=75"
                              style="max-width: 170px; padding-right: 5px"
                          /></a>
                          <a href="https://testflight.apple.com/join/7WhJvJmj" target="_blank"
                            ><img
                              src="https://app.shopimint.com/backend/static/teststore.png"
                              style="max-width: 170px; padding-right: 5px"
                          /></a>
                         
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3>Step 2 :</h3>
                    <h3>Scan the QR Code with our app to preview your app.</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <h3>Step 3 :</h3>
                    <h3>
                      Now you can preview your changes live throught the app.
                    </h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cache" max-width="360px" >
      <v-card>
        <v-card-title class="text-h5"> Cached Cleared  Successfully </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cache = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MenuIcon from "@/components/icons/MenuIcon";
import QrIcon from "@/components/icons/QrIcon";
import PageChange from "./PageChange.vue";
import ImageIcon from "../icons/ImageIcon.vue";
// import ChevronLeftIcon from "@/components/icons/ChevronLeftIcon";
// import ChevronRightIcon from "@/components/icons/ChevronRightIcon";

export default {
  name: 'PageBar',
  props: {
    title: {
      type: String
    },
    pagetype: {
      type: String,
      default: '',
    },
    back: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array
    },
    drawer: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: true
    },
  },
  components: {
    MenuIcon,
    // ChevronLeftIcon,
    // ChevronRightIcon,
    QrIcon,
    PageChange,
    ImageIcon
},
  data: () => ({
    tabModel: 0,
    dialog: false,
    icon: null,
    loading: true,
    cacheloading: false,
    cache:false,
    apps: [],
  }),
  watch: {
    cache (val) {
      if (!val) return
      setTimeout(() => (this.cache = false), 1500)
    },
  },
  methods: {
    setDrawer() {
      let value = this.$store.state.left_drawer ? false : true;
      this.$store.commit('setLeftDrawer', value);
    },
    setPreview() {
      let value = this.$store.state.right_drawer ? false : true;
      this.$store.commit('setRightDrawer', value);
    },
    refreshcache() {
      this.cacheloading = true;
      this.$http.get(`${this.$serverApiLink}api/account/cachemanager/clearcache/${this.$route.params.uid}`).
      then(
          response => {
            console.log(response);
            this.cacheloading = false;
            this.cache = true;
          },

      ).catch(
          error => {
            this.$store.commit('setSnackBar', {
              code: !error.response ? 408 : error.response.status,
              message: error.response.data.message
            });
            this.cacheloading = false;
          }
      );
    },
    getAppShortInfo() {
      this.loading = true;
      this.$http.get(`${this.$serverApiLink}api/account/apps/short/${this.$route.params.uid}`).
      then(
          response => {
            this.apps = response.data.app;
            this.loading = false;
          }
      )
      // .catch(
      //     error => {
      //       this.$store.commit('setSnackBar', {
      //         code: !error.response ? 408 : error.response.status,
      //         message: error.response.data.message
      //       });
      //       this.loading = false;
      //     }
      // );
    },
  },
  mounted() {
    this.getAppShortInfo();
  }
}
</script>
