<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 10C22.4183 10 26 13.5817 26 18C26 22.4183 22.4183 26 18 26C13.5817 26 10 22.4183 10 18C10 13.5817 13.5817 10 18 10ZM12.8722 2C14.6488 2 15.5198 2.16819 16.4253 2.65245C17.2514 3.09427 17.9057 3.74859 18.3475 4.57471C18.7165 5.26466 18.8935 5.90976 18.9645 6.93609C19.0027 7.48706 18.5869 7.96462 18.0359 8.00275C17.485 8.04088 17.0074 7.62514 16.9693 7.07417C16.9172 6.32166 16.8155 5.95099 16.5839 5.51791C16.3285 5.04032 15.9597 4.67149 15.4821 4.41608C14.9737 4.14421 14.4787 4.02817 13.3566 4.00463L12.8727 4L9.8672 4.00147C8.57768 4.01654 8.06367 4.1242 7.51791 4.41608C7.04032 4.67149 6.67149 5.04032 6.41608 5.51791C6.14421 6.02626 6.02817 6.52132 6.00463 7.64371L6 8.12764L6.00147 20.1328C6.01654 21.4223 6.1242 21.9363 6.41608 22.4821C6.67149 22.9597 7.04032 23.3285 7.51791 23.5839C8.02091 23.8529 8.4762 23.9592 9.53583 23.9917C10.0879 24.0086 10.5216 24.4698 10.5047 25.0219C10.4878 25.5739 10.0265 26.0077 9.47451 25.9907C8.12499 25.9493 7.37099 25.7734 6.57471 25.3475C5.74859 24.9057 5.09427 24.2514 4.65245 23.4253C4.22107 22.6187 4.0428 21.8458 4.00686 20.4354L4.00154 20.1446L4 8.12777C4 6.35117 4.16819 5.48019 4.65245 4.57471C5.09427 3.74859 5.74859 3.09427 6.57471 2.65245C7.38132 2.22107 8.15417 2.0428 9.56429 2.00686L9.85501 2.00154L12.8722 2ZM18 12C14.6863 12 12 14.6863 12 18C12 21.3137 14.6863 24 18 24C21.3137 24 24 21.3137 24 18C24 14.6863 21.3137 12 18 12ZM21.5364 15.5636C21.8586 15.8858 21.8854 16.3915 21.6169 16.7442L21.5364 16.8364L17.5364 20.8364C17.2142 21.1586 16.7085 21.1854 16.3558 20.9169L16.2636 20.8364L14.6636 19.2364C14.3121 18.8849 14.3121 18.3151 14.6636 17.9636C14.9858 17.6414 15.4915 17.6146 15.8442 17.8831L15.9364 17.9636L16.9 18.927L20.2636 15.5636C20.6151 15.2121 21.1849 15.2121 21.5364 15.5636Z" fill="currentColor"/>
  </svg>

</template>

<script>
export default {
  name: 'CheckDeviceIcon',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>